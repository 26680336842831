<template>
    <div class="row" id="portfolioChart">
        <div class="mb-4 col-md-4 mb-md-0 text-center">
            <div class="bg-white">
                <div class="title">{{ translate('portfolio') }}</div>
                <div class="data">
                    <span><i>{{sumCount}}</i><br>{{ translate('projects') }}</span>
                    <span><i>{{sumDsIntrests}}%</i><br>{{ translate('return_per_year') }}</span>
                </div>
                <div class="donutChart">
                    <div v-for="item in parts" :key="item.name" >
                        <div v-if="item.dag <= 180 && item.dag > 0" class="portionBlock" :style="{ transform: 'rotate('+item.start+'deg)' }">
                            <div class="circle" :style="{ transform: 'rotate('+item.dag+'deg)', background: '#'+item.color }"></div>
                        </div>
                        <div v-if="item.dag > 180" class="portionBlock" :style="{ transform: 'rotate('+item.start+'deg)' }">
                            <div class="circle" :style="{ transform: 'rotate(180deg)', background: '#'+item.color }"></div>
                        </div>
                        <div v-if="item.dag > 180" class="portionBlock" :style="{ transform: 'rotate('+(item.start+180)+'deg)' }">
                            <div class="circle" :style="{ transform: 'rotate('+(item.dag-180)+'deg)', background: '#'+item.color }"></div>
                        </div>
                    </div>
                    <div class="center"></div>
                    <div class="innerShadow"></div>
                    <div class="outerShadow"></div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="bg-white">
                <div class="title">{{ translate('performance') }}</div>
                <div v-if="isonall">{{ translate('portfolio_selection_info') }}</div>
                <table class="d-none d-sm-table">
                    <thead>
                        <tr>
                            <td v-if="isonall">{{ translate('selection') }}</td>
                            <td v-else></td>
                            <td></td>
                            <td>{{ translate('amount_alt') }}</td>
                            <td>{{ translate('invested') }}</td>
                            <td>&Oslash; {{ translate('investment') }}</td>
                            <td>{{ translate('revenue') }}</td>
                            <td>&Oslash; {{ translate('duration') }}</td>
                            <td>&Oslash; {{ translate('return_per_year') }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in parts" :key="item.name">
                            <td v-if="isonall"><div :id=item.color class="color" :style="{ border: '#'+item.color+' 3px solid' }" :title="translate(item.description)" @click="filter(item.color)"></div></td>
                            <td v-else><div class="color" :style="{ background: '#'+item.color }" :title="translate(item.description)"></div></td>
                            <td :title="translate(item.description)">{{ translate(item.name) }}</td>
                            <td>{{item.count}}</td>
                            <td>{{item.invested}}</td>
                            <td>{{item.dsInvested}}</td>
                            <td>{{item.profit}}</td>
                            <td v-if="item.dsDuration != '0,00' && item.name != 'ausgefallen'">{{item.dsDuration}}</td><td v-else></td>
                            <td v-if="item.dsIntrests != '0,00' && !['in Betreibung', 'ausgefallen'].includes(item.name)">{{item.dsIntrests}}%</td><td v-else></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{{ translate('actions') }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{{this.actionsValue}}</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr class="sum">
                            <td v-if="isonall"><div id="sum" class="color" style="border: #000 3px solid;text-align: center; line-height: 18px; font-size: 24px; color: #000;" :title="translate('total')" @click="filter('sum')"></div></td>
                            <td v-else></td>
                            <td>{{ translate('total') }}</td>
                            <td>{{sumCount}}</td>
                            <td>{{sumInvested}}</td>
                            <td>{{sumDsInvested}}</td>
                            <td>{{sumProfit}}</td>
                            <td>{{sumDsDuration}}</td>
                            <td>{{sumDsIntrests}}%</td>
                        </tr>
                        <tr v-if="sumRealisiert > 0">
                            <td v-if="isonall"><div id="realisiert" class="color" style="border: #000 3px solid;text-align: center; line-height: 18px; font-size: 24px; color: #000; font-weight: 600;" :title="translate('realized')" @click="filter('realisiert')"></div></td>
                            <td v-else></td>
                            <td>{{ translate('realized') }}</td>
                            <td>{{sumRealisiert}}</td>
                            <td>{{sumInvestedRealisiert}}</td>
                            <td>{{sumDsInvestedRealisiert}}</td>
                            <td>{{sumProfitRealisiert}}</td>
                            <td>{{sumDsDurationRealisiert}}</td>
                            <td>{{sumDsIntrestsRealisiert}}%</td>
                        </tr>
                        <tr v-if="sumInvestiert > 0">
                            <td v-if="isonall"><div id="investiert" class="color" style="border: #000 3px solid;text-align: center; line-height: 18px; font-size: 24px; color: #000; font-weight: 600;" :title="translate('invested_lowercase')" @click="filter('investiert')"></div></td>
                            <td v-else></td>
                            <td>{{ translate('invested_lowercase') }}</td>
                            <td>{{sumInvestiert}}</td>
                            <td>{{sumInvestedInvestiert}}</td>
                            <td>{{sumDsInvestedInvestiert}}</td>
                            <td>{{sumProfitInvestiert}}</td>
                            <td>{{sumDsDurationInvestiert}}</td>
                            <td>{{sumDsIntrestsInvestiert}}%</td>
                        </tr>
                        <tr v-if="this.unpaid[0].count > 0">
                            <td v-if="isonall"><div id="unbezahlt" class="color" style="border: #000 3px solid;text-align: center; line-height: 18px; font-size: 24px; color: #000; font-weight: 600;" :title="translate('unpaid')" @click="filter('unbezahlt')"></div></td>
                            <td v-else></td>
                            <td>{{ translate('unpaid') }}</td>
                            <td>{{this.unpaid[0].count}}</td>
                            <td>{{unpaidValue}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr v-if="this.unpaid[1].count > 0">
                            <td></td>
                            <td>{{ translate('reserved') }}</td>
                            <td>{{this.unpaid[1].count}}</td>
                            <td>{{reservedValue}}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <table class="d-sm-none mobileTable" v-for="item in parts" :key="item.name">
                    <tr>
                        <td v-if="isonall"><div :id="item.color+'m'" class="color" :style="{ border: '#'+item.color+' 3px solid' }" :title="translate(item.description)" @click="filter(item.color)"></div></td>
                        <td v-else><div class="color" :style="{ background: '#'+item.color }" :title="translate(item.description)"></div></td>
                        <td colspan="2" class="text-left"><h5>{{ translate(item.name) }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            {{item.count}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{item.invested}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('investment') }}</b><br>
                            {{item.dsInvested}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('revenue') }}</b><br>
                            {{item.profit}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('duration') }}</b><br>
                            <span v-if="item.dsDuration != '0,00' && item.name != 'ausgefallen'">{{item.dsDuration}}</span>
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('return_per_year') }}</b><br>
                            <span v-if="item.dsIntrests != '0,00' && !['in Betreibung', 'ausgefallen'].includes(item.name)">{{item.dsIntrests}}%</span>
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable">
                    <tr>
                        <td width="1px"><div class="color"></div></td>
                        <td colspan="2" class="text-left"><h5>{{ translate('actions') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            &nbsp;
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            &nbsp;
                        </td>
                        <td>
                            <b>{{ translate('average_investment') }}</b><br>
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('revenue') }}</b><br>
                            {{this.actionsValue}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('duration') }}</b><br>
                            &nbsp;
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('return_per_year') }}</b><br>
                            &nbsp;
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable">
                    <tr>
                        <td v-if="isonall"><div id="summ" class="color" style="border: #000 3px solid;text-align: center; line-height: 18px; font-size: 24px; color: #000;" :title="translate('total')" @click="filter('sum')"></div></td>
                        <td v-else width="1px"><div class="color"></div></td>
                        <td colspan="2" class="text-left"><h5>{{ translate('total') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            {{sumCount}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{sumInvested}}
                        </td>
                        <td>
                            <b>{{ translate('average_investment') }}</b><br>
                            {{sumDsInvested}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('revenue') }}</b><br>
                            {{sumProfit}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('duration') }}</b><br>
                            {{sumDsDuration}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('return_per_year') }}</b><br>
                            {{sumDsIntrests}}%
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable" v-if="sumRealisiert > 0">
                    <tr>
                        <td v-if="isonall"><div id="realisiertm" class="color" style="border: #000 3px solid;text-align: center; line-height: 18px; font-size: 24px; color: #000; font-weight: 600;" :title="translate('realized')" @click="filter('realisiert')"></div></td>
                        <td v-else width="1px"><div class="color"></div></td>
                        <td colspan="2" class="text-left"><h5>{{ translate('realized') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            {{sumRealisiert}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{sumInvestedRealisiert}}
                        </td>
                        <td>
                            <b>{{ translate('average_investment') }}</b><br>
                            {{sumDsInvestedRealisiert}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('revenue') }}</b><br>
                            {{sumProfitRealisiert}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('duration') }}</b><br>
                            {{sumDsDurationRealisiert}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('return_per_year') }}</b><br>
                            {{sumDsIntrestsRealisiert}}%
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable" v-if="sumInvestiert > 0">
                    <tr>
                        <td v-if="isonall"><div id="investiertm" class="color" style="border: #000 3px solid;text-align: center; line-height: 18px; font-size: 24px; color: #000; font-weight: 600;" :title="translate('invested_lowercase')" @click="filter('investiert')"></div></td>
                        <td v-else width="1px"><div class="color"></div></td>
                        <td colspan="2" class="text-left"><h5>{{ translate('invested_lowercase') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            {{sumInvestiert}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{sumInvestedInvestiert}}
                        </td>
                        <td>
                            <b>{{ translate('average_investment') }}</b><br>
                            {{sumDsInvestedInvestiert}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('revenue') }}</b><br>
                            {{sumProfitInvestiert}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('duration') }}</b><br>
                            {{sumDsDurationInvestiert}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('return_per_year') }}</b><br>
                            {{sumDsIntrestsInvestiert}}%
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable" v-if="this.unpaid[0].count > 0">
                    <tr>
                        <td v-if="isonall"><div id="unbezahltm" class="color" style="border: #000 3px solid;text-align: center; line-height: 18px; font-size: 24px; color: #000; font-weight: 600;" :title="translate('unpaid')" @click="filter('unbezahlt')"></div></td>
                        <td v-else width="1px"><div class="color"></div></td>
                        <td class="text-left"><h5>{{ translate('unpaid') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            {{this.unpaid[0].count}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{unpaidValue}}
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable" v-if="this.unpaid[1].count > 0">
                    <tr>
                        <td width="1px"><div class="color"></div></td>
                        <td class="text-left"><h5>{{ translate('reserved') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('amount_alt') }}</b><br>
                            {{this.unpaid[1].count}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{reservedValue}}
                        </td>
                    </tr>
                </table>
                <div class="performance-calculation-info mt-5 mt-sm-3">
                    {{ translate('performance_calculation_info') }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            values: Array,
            unpaid: Array,
            gap: {
                type: String,
                default: "0"
            },
            actions: {
                type: String,
                default: "0"
            },
            isonall: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            sumCount() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].count != "") {
                        sum = sum + this.values[i].count;
                    }
                }

                return sum;
            },

            unpaidValue() {
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.unpaid[0].value));
            },

            reservedValue()  {
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.unpaid[1].value));
            },

            sumInvested() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].invested != "") {
                        sum = sum + this.values[i].invested;
                    }
                }

                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsInvested() {
                let sum = 0;
                let sumCount = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].invested != "") {
                        sum = sum + this.values[i].invested;
                    }
                    if(this.values[i].count != "") {
                        sumCount = sumCount + this.values[i].count;
                    }
                }

                sum = sum/sumCount;
                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));

                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumProfit() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].profit != "") {
                        sum = sum + this.values[i].profit;
                    }
                }
                sum = sum + parseFloat(this.actions);
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsDuration() {
                let sumDays = 0;
                let sumCounts = 0;
                for(let i = 0; i < this.values.length; i++) {
                    sumDays = sumDays + this.values[i].sumDays;
                    sumCounts = sumCounts + this.values[i].count;
                }

                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sumDays / sumCounts / 365 * 12));
                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumDsIntrests() {
                let sumProfit = 0;
                let sumCapitalfact = 0;
                for(let i = 0; i < this.values.length; i++) {
                    sumProfit = sumProfit + this.values[i].profit;
                    sumCapitalfact = sumCapitalfact + this.values[i].sumCapitalfact;
                }

                sumProfit = sumProfit + parseFloat(this.actions);

                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sumProfit * 365 / sumCapitalfact * 100));

                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumRealisiert() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].count != "" && (this.values[i].name == 'zurückbezahlt' || this.values[i].name == 'ausgefallen')) {
                        sum = sum + this.values[i].count;
                    }
                }

                return sum;
            },

            sumInvestedRealisiert() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].invested != "" && (this.values[i].name == 'zurückbezahlt' || this.values[i].name == 'ausgefallen')) {
                        sum = sum + this.values[i].invested;
                    }
                }

                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsInvestedRealisiert() {
                let sum = 0;
                let sumCount = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].invested != "" && (this.values[i].name == 'zurückbezahlt' || this.values[i].name == 'ausgefallen')) {
                        sum = sum + this.values[i].invested;
                    }
                    if(this.values[i].count != "" && (this.values[i].name == 'zurückbezahlt' || this.values[i].name == 'ausgefallen')) {
                        sumCount = sumCount + this.values[i].count;
                    }
                }

                sum = sum/sumCount;
                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));

                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumProfitRealisiert() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].profit != "" && (this.values[i].name == 'zurückbezahlt' || this.values[i].name == 'ausgefallen')) {
                        sum = sum + this.values[i].profit;
                    }
                }
                sum = sum + parseFloat(this.actions);

                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsDurationRealisiert() {
                let sumDays = 0;
                let sumCounts = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].name == 'zurückbezahlt' || this.values[i].name == 'ausgefallen') {
                        sumDays = sumDays + this.values[i].sumDays;
                        sumCounts = sumCounts + this.values[i].count;
                    }
                }

                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sumDays / sumCounts / 365 * 12));
                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumDsIntrestsRealisiert() {
                let sumProfit = 0;
                let sumCapitalfact = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].name == 'zurückbezahlt' || this.values[i].name == 'ausgefallen') {
                        sumProfit = sumProfit + this.values[i].profit;
                        sumCapitalfact = sumCapitalfact + this.values[i].sumCapitalfact;
                    }
                }

                sumProfit = sumProfit + parseFloat(this.actions);

                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sumProfit * 365 / sumCapitalfact * 100));

                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumInvestiert() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].count != "" && (this.values[i].name == 'im Verdienen' || this.values[i].name == 'verlängert' || this.values[i].name == 'verspätet' || this.values[i].name == 'in Betreibung')) {
                        sum = sum + this.values[i].count;
                    }
                }

                return sum;
            },

            sumInvestedInvestiert() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].invested != "" && (this.values[i].name == 'im Verdienen' || this.values[i].name == 'verlängert' || this.values[i].name == 'verspätet' || this.values[i].name == 'in Betreibung')) {
                        sum = sum + this.values[i].invested;
                    }
                }

                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsInvestedInvestiert() {
                let sum = 0;
                let sumCount = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].invested != "" && (this.values[i].name == 'im Verdienen' || this.values[i].name == 'verlängert' || this.values[i].name == 'verspätet' || this.values[i].name == 'in Betreibung')) {
                        sum = sum + this.values[i].invested;
                    }
                    if(this.values[i].count != "" && (this.values[i].name == 'im Verdienen' || this.values[i].name == 'verlängert' || this.values[i].name == 'verspätet' || this.values[i].name == 'in Betreibung')) {
                        sumCount = sumCount + this.values[i].count;
                    }
                }

                sum = sum/sumCount;
                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));

                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumProfitInvestiert() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].profit != "" && (this.values[i].name == 'im Verdienen' || this.values[i].name == 'verlängert' || this.values[i].name == 'verspätet' || this.values[i].name == 'in Betreibung')) {
                        sum = sum + this.values[i].profit;
                    }
                }

                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsDurationInvestiert() {
                let sumDays = 0;
                let sumCounts = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].name == 'im Verdienen' || this.values[i].name == 'verlängert' || this.values[i].name == 'verspätet' || this.values[i].name == 'in Betreibung') {
                        sumDays = sumDays + this.values[i].sumDays;
                        sumCounts = sumCounts + this.values[i].count;
                    }
                }

                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sumDays / sumCounts / 365 * 12));
                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumDsIntrestsInvestiert() {
                let sumProfit = 0;
                let sumCapitalfact = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].name == 'im Verdienen' || this.values[i].name == 'verlängert' || this.values[i].name == 'verspätet' || this.values[i].name == 'in Betreibung') {
                        sumProfit = sumProfit + this.values[i].profit;
                        sumCapitalfact = sumCapitalfact + this.values[i].sumCapitalfact;
                    }
                }

                sumProfit = sumProfit + parseFloat(this.actions);

                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sumProfit * 365 / sumCapitalfact * 100));

                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },
        },

        created() {
            let start = 0;
            let sumValueStart = 0;
            for(let i = 0; i < this.values.length; i++) {
                sumValueStart = sumValueStart + this.values[i].count;
            }
            for(let i = 0; i < this.values.length; i++) {
                let tmp = {
                    name: this.values[i].name,
                    count: this.values[i].count,
                    description: this.values[i].description,
                    invested: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.values[i].invested)),
                    dsInvested: this.values[i].count > 0 ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.values[i].invested / this.values[i].count)) : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((0)),
                    profit: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.values[i].profit)),
                    dsDuration: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.values[i].dsDuration)),
                    dsIntrests: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.values[i].dsIntrests)),
                    value: this.values[i].value,
                    color: this.values[i].color,
                    start: start,
                    dag: 360 * (parseInt(this.values[i].count) / sumValueStart) - parseInt(this.gap)
                };
                this.parts.push(tmp);
                start = start + 360 * (parseInt(this.values[i].count) / sumValueStart);
            }

            this.actionsValue = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.actions));
        },

        methods: {
            filter(color) {
                this.filters = [];

                if(color == 'sum') {
                    if(document.getElementById('realisiert') !== null) {
                        document.getElementById('realisiert').innerHTML = '';
                        document.getElementById('realisiertm').innerHTML = '';
                    }
                    if(document.getElementById('investiert') !== null) {
                        document.getElementById('investiert').innerHTML = '';
                    }
                    for(let i = 0; i < this.values.length; i++) {
                        document.getElementById(this.values[i].color).style.backgroundColor = '';
                        document.getElementById(this.values[i].color+'m').style.backgroundColor = '';
                    }
                    if(document.getElementById('unbezahlt') !== null) {
                        document.getElementById('unbezahlt').style.backgroundColor = '';
                        document.getElementById('unbezahltm').style.backgroundColor = '';
                    }

                    if(document.getElementById(color).innerHTML == 'X') {
                        document.getElementById(color).innerHTML = '';
                        document.getElementById(color+'m').innerHTML = '';
                    } else {
                        document.getElementById(color).innerHTML = 'X';
                        document.getElementById(color+'m').innerHTML = 'X';
                        for(let i = 0; i < this.values.length; i++) {
                            document.getElementById(this.values[i].color).style.backgroundColor = '#'+this.values[i].color;
                            document.getElementById(this.values[i].color+'m').style.backgroundColor = '#'+this.values[i].color;
                        }
                    }
                } else if(color == 'realisiert'){
                    document.getElementById('sum').innerHTML = '';
                    document.getElementById('summ').innerHTML = '';
                    if(document.getElementById('investiert') !== null) {
                        document.getElementById('investiert').innerHTML = '';
                        document.getElementById('investiertm').innerHTML = '';
                    }
                    for(let i = 0; i < this.values.length; i++) {
                        document.getElementById(this.values[i].color).style.backgroundColor = '';
                        document.getElementById(this.values[i].color+'m').style.backgroundColor = '';
                    }
                    if(document.getElementById('unbezahlt') !== null) {
                        document.getElementById('unbezahlt').style.backgroundColor = '';
                        document.getElementById('unbezahltm').style.backgroundColor = '';
                    }

                    if(document.getElementById(color).innerHTML == 'X') {
                        document.getElementById(color).innerHTML = '';
                        document.getElementById(color+'m').innerHTML = '';
                    } else {
                        document.getElementById(color).innerHTML = 'X';
                        document.getElementById(color+'m').innerHTML = 'X';
                        for(let i = 0; i < this.values.length; i++) {
                            if(i == 0 || i == 4) {
                                document.getElementById(this.values[i].color).style.backgroundColor = '#'+this.values[i].color;
                                document.getElementById(this.values[i].color+'m').style.backgroundColor = '#'+this.values[i].color;
                            }
                        }
                    }
                } else if(color == 'investiert'){
                    document.getElementById('sum').innerHTML = '';
                    document.getElementById('summ').innerHTML = '';
                    if(document.getElementById('realisiert') !== null) {
                        document.getElementById('realisiert').innerHTML = '';
                        document.getElementById('realisiertm').innerHTML = '';
                    }
                    for(let i = 0; i < this.values.length; i++) {
                        document.getElementById(this.values[i].color).style.backgroundColor = '';
                        document.getElementById(this.values[i].color+'m').style.backgroundColor = '';
                    }
                    if(document.getElementById('unbezahlt') !== null) {
                        document.getElementById('unbezahlt').style.backgroundColor = '';
                        document.getElementById('unbezahltm').style.backgroundColor = '';
                    }

                    if(document.getElementById(color).innerHTML == 'X') {
                        document.getElementById(color).innerHTML = '';
                        document.getElementById(color+'m').innerHTML = '';
                    } else {
                        document.getElementById(color).innerHTML = 'X';
                        document.getElementById(color+'m').innerHTML = 'X';
                        for(let i = 0; i < this.values.length; i++) {
                            if(i > 0 && i < 4) {
                                document.getElementById(this.values[i].color).style.backgroundColor = '#'+this.values[i].color;
                                document.getElementById(this.values[i].color+'m').style.backgroundColor = '#'+this.values[i].color;
                            }
                        }
                    }
                }  else if(color == 'unbezahlt'){
                    document.getElementById('sum').innerHTML = '';
                    document.getElementById('summ').innerHTML = '';
                    if(document.getElementById('realisiert') !== null) {
                        document.getElementById('realisiert').innerHTML = '';
                        document.getElementById('realisiertm').innerHTML = '';
                    }
                    if(document.getElementById('investiert') !== null) {
                        document.getElementById('investiert').innerHTML = '';
                        document.getElementById('investiertm').innerHTML = '';
                    }

                    if(document.getElementById(color).style.backgroundColor != '') {
                        document.getElementById(color).style.backgroundColor = '';
                        document.getElementById(color+'m').style.backgroundColor = '';
                    } else {
                        document.getElementById(color).style.backgroundColor = '#000';
                        document.getElementById(color+'m').style.backgroundColor = '#000';
                    }
                } else {
                    document.getElementById('sum').innerHTML = '';
                    document.getElementById('summ').innerHTML = '';
                    if(document.getElementById('realisiert') !== null) {
                        document.getElementById('realisiert').innerHTML = '';
                        document.getElementById('realisiertm').innerHTML = '';
                    }
                    if(document.getElementById('investiert') !== null) {
                        document.getElementById('investiert').innerHTML = '';
                        document.getElementById('investiertm').innerHTML = '';
                    }
                    if(document.getElementById(color).style.backgroundColor != '') {
                        document.getElementById(color).style.backgroundColor = '';
                        document.getElementById(color+"m").style.backgroundColor = '';
                    } else {
                        document.getElementById(color).style.backgroundColor = '#'+color;
                        document.getElementById(color+"m").style.backgroundColor = '#'+color;
                    }
                }

                for(let i = 0; i < this.values.length; i++) {
                    if(document.getElementById(this.values[i].color).style.backgroundColor != '') {
                        this.filters.push(this.values[i].name);
                        if(this.values[i].name == 'im Verdienen') {
                            this.filters.push('verlängert');
                            this.filters.push('verspätet');
                        }
                    }
                }
                if(document.getElementById('unbezahlt') !== null) {
                    if(document.getElementById('unbezahlt').style.backgroundColor != '') {
                        this.filters.push('unbezahlt');
                    }
                }

                window.App.dispatcher.fire('filterChange', { filters: this.filters })
            }
        },

        data() {
            return {
                parts: [],
                filters: [],
                actionsValue: 0
            };
        }
    }
</script>

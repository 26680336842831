<template>
    <!--
        (Temporarily?) disable the rendering of the portfolio chart; this
        component isn't just fully removed instead to keep the filtering still
        working when using the query parameter (e.g.,
        `?filter%5B%5D=paid-back`) directly.

        To enable the rendering again and revert to the old display, simply
        remove this `v-if="false"` and change `'disableBottomSpacing' => true`
        back to `'disableBottomSpacing' => false` for the
        `@include('layouts.subheaderAlt')` in
        `resources/views/closedProjects.blade.php
    -->
    <div class="row" id="portfolioChart" v-if="false">
        <div class="mb-4 col-md-4 mb-md-0 text-center">
            <div class="bg-white">
                <div class="title">{{ translate('portfolio') }}</div>
                <div class="data">
                    <span><i>{{sumCount}}</i><br>{{ translate('investments') }}</span>
                    <span><i>{{sumDsIntrests}}%</i><br>{{ translate('return_per_year') }}</span>
                </div>
                <div class="donutChart">
                    <div v-for="item in parts" :key="item.name" >
                        <div v-if="item.dag <= 180 && item.dag > 0" class="portionBlock" :style="{ transform: 'rotate('+item.start+'deg)' }">
                            <div class="circle" :style="{ transform: 'rotate('+item.dag+'deg)', background: '#'+item.color }"></div>
                        </div>
                        <div v-if="item.dag > 180" class="portionBlock" :style="{ transform: 'rotate('+item.start+'deg)' }">
                            <div class="circle" :style="{ transform: 'rotate(180deg)', background: '#'+item.color }"></div>
                        </div>
                        <div v-if="item.dag > 180" class="portionBlock" :style="{ transform: 'rotate('+(item.start+180)+'deg)' }">
                            <div class="circle" :style="{ transform: 'rotate('+(item.dag-180)+'deg)', background: '#'+item.color }"></div>
                        </div>
                    </div>
                    <div class="center"></div>
                    <div class="innerShadow"></div>
                    <div class="outerShadow"></div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="bg-white">
                <div class="title">{{ translate('performance') }}</div>
                <div>{{ translate('dagobertinvest_trade_balance_at_a_glance') }}</div>
                <table class="d-none d-sm-table">
                    <thead>
                        <tr>
                            <td>{{ translate('category') }}</td>
                            <td></td>
                            <td>{{ translate('investments') }}</td>
                            <td>{{ translate('invested') }}</td>
                            <td>&Oslash; {{ translate('investment') }}</td>
                            <td>{{ translate('revenue') }}</td>
                            <td>&Oslash; {{ translate('duration') }}</td>
                            <td>&Oslash; {{ translate('return_per_year') }}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in parts" :key="item.name">
                            <td><div :id=item.color class="color" :style="{ border: '#'+item.color+' 3px solid' }" :title="translate(item.description)" @click="filter(item.color, true)"></div></td>
                            <td :title="translate(item.description)">{{ translate(item.name) }}</td>
                            <td>{{item.count | formatedNumber}}</td>
                            <td>{{item.invested}}</td>
                            <td>{{item.dsInvested}}</td>
                            <td>{{item.profit}}</td>
                            <td v-if="item.dsDuration != '0,00' && item.name != 'ausgefallen'">{{item.dsDuration}}</td><td v-else></td>
                            <td v-if="item.dsIntrests != '0,00' && !['in Betreibung', 'ausgefallen'].includes(item.name)">{{item.dsIntrests}}%</td><td v-else></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{{ translate('actions') }}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>{{this.actionsValue}}</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr class="sum">
                            <td><div id="sum" class="color" style="border: #000 3px solid;text-align: center; line-height: 18px; font-size: 24px; color: #000;" :title="translate('total')" @click="filter('sum', true)"></div></td>
                            <td>{{ translate('total') }}</td>
                            <td>{{sumCount}}</td>
                            <td>{{sumInvested}}</td>
                            <td>{{sumDsInvested}}</td>
                            <td>{{sumProfit}}</td>
                            <td>{{sumDsDuration}}</td>
                            <td>{{sumDsIntrests}}%</td>
                        </tr>
                    </tbody>
                </table>
                <table class="d-sm-none mobileTable" v-for="item in parts" :key="item.name">
                    <tr>
                        <td><div :id="item.color+'m'" class="color" :style="{ border: '#'+item.color+' 3px solid' }" :title="translate(item.description)" @click="filter(item.color, true)"></div></td>
                        <td colspan="2" class="text-left"><h5>{{ translate(item.name) }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('investments') }}</b><br>
                            {{item.count | formatedNumber}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{item.invested}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('investment') }}</b><br>
                            {{item.dsInvested}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('revenue') }}</b><br>
                            {{item.profit}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('duration') }}</b><br>
                            <span v-if="item.dsDuration != '0,00' && item.name != 'ausgefallen'">{{item.dsDuration}}</span>
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('return_per_year') }}</b><br>
                            <span v-if="item.dsIntrests != '0,00' && !['in Betreibung', 'ausgefallen'].includes(item.name)">{{item.dsIntrests}}%</span>
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable">
                    <tr>
                        <td width="1px"><div class="color"></div></td>
                        <td colspan="2" class="text-left"><h5>{{ translate('actions') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('investments') }}</b><br>
                            &nbsp;
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            &nbsp;
                        </td>
                        <td>
                            <b>{{ translate('average_investment') }}</b><br>
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('revenue') }}</b><br>
                            {{this.actionsValue}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('duration') }}</b><br>
                            &nbsp;
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('return_per_year') }}</b><br>
                            &nbsp;
                        </td>
                    </tr>
                </table>
                <table class="d-sm-none mobileTable">
                    <tr>
                        <td><div id="summ" class="color" style="border: #000 3px solid;text-align: center; line-height: 18px; font-size: 24px; color: #000;" :title="translate('total')" @click="filter('sum', true)"></div></td>
                        <td colspan="2" class="text-left"><h5>{{ translate('total') }}</h5></td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('investments') }}</b><br>
                            {{sumCount}}
                        </td>
                        <td>
                            <b>{{ translate('invested') }}</b><br>
                            {{sumInvested}}
                        </td>
                        <td>
                            <b>{{ translate('average_investment') }}</b><br>
                            {{sumDsInvested}}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>{{ translate('revenue') }}</b><br>
                            {{sumProfit}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('duration') }}</b><br>
                            {{sumDsDuration}}
                        </td>
                        <td>
                            <b>&Oslash; {{ translate('return_per_year') }}</b><br>
                            {{sumDsIntrests}}%
                        </td>
                    </tr>
                </table>
                <div class="portfolio-description">
                    <p v-html="translate('dagobertinvest_portfolio_overview', [{ key: 'AMOUNT_OF_PROJECTS', value: $options.filters.formatedNumber(amountOfProjects) }, { key: 'AMOUNT_OF_INVESTMENTS', value: $options.filters.formatedNumber(paidBackInvestments) }, { key: 'INTEREST_VALUE', value: paidBackProfit }])"></p>
                    <p>{{ translate('dagobertinvest_portfolio_showing_all_projects') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            values: Array,
            gap: {
                type: String,
                default: "0"
            },
            actions: {
                type: String,
                default: "0"
            },
            amountOfProjects: {
                type: String,
                default: "0"
            }
        },

        filters: {
            formatedNumber(value) {
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format((value));
            }
        },

        computed: {
            sumCount() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].count != "") {
                        sum = sum + this.values[i].count;
                    }
                }

                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format((sum));
            },

            sumInvested() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].invested != "") {
                        sum = sum + this.values[i].invested;
                    }
                }

                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsInvested() {
                let sum = 0;
                let sumCount = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].invested != "") {
                        sum = sum + this.values[i].invested;
                    }
                    if(this.values[i].count != "") {
                        sumCount = sumCount + this.values[i].count;
                    }
                }

                sum = sum/sumCount;
                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));

                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumProfit() {
                let sum = 0;
                for(let i = 0; i < this.values.length; i++) {
                    if(this.values[i].profit != "") {
                        sum = sum + this.values[i].profit;
                    }
                }
                sum = sum + parseFloat(this.actions);
                return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sum));
            },

            sumDsDuration() {
                let sumDays = 0;
                let sumCounts = 0;
                for(let i = 0; i < this.values.length; i++) {
                    sumDays = sumDays + this.values[i].sumDays;
                    sumCounts = sumCounts + this.values[i].count;
                }

                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sumDays / sumCounts / 365 * 12));
                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            sumDsIntrests() {
                let sumProfit = 0;
                let sumCapitalfact = 0;
                for(let i = 0; i < this.values.length; i++) {
                    sumProfit = sumProfit + this.values[i].profit;
                    sumCapitalfact = sumCapitalfact + this.values[i].sumCapitalfact;
                }

                sumProfit = sumProfit + parseFloat(this.actions);

                let result = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((sumProfit * 365 / sumCapitalfact * 100));

                if(result == "NaN") {
                    return 0;
                } else {
                    return result;
                }
            },

            paidBackInvestments() {
                if (this.parts.length === 0) {
                    return '';
                }

                return this.parts[0].count;
            },

            paidBackProfit() {
                if (this.parts.length === 0) {
                    return '';
                }

                return this.parts[0].profit;
            },
        },

        created() {
            let start = 0;
            let sumValueStart = 0;
            for(let i = 0; i < this.values.length; i++) {
                sumValueStart = sumValueStart + this.values[i].count;
            }
            for(let i = 0; i < this.values.length; i++) {
                let tmp = {
                    name: this.values[i].name,
                    count: this.values[i].count,
                    description: this.values[i].description,
                    invested: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.values[i].invested)),
                    dsInvested: this.values[i].count > 0 ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.values[i].invested / this.values[i].count)) : new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((0)),
                    profit: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.values[i].profit)),
                    dsDuration: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.values[i].dsDuration)),
                    dsIntrests: new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.values[i].dsIntrests)),
                    value: this.values[i].value,
                    color: this.values[i].color,
                    filterName: this.values[i].filterName,
                    start: start,
                    dag: 360 * (parseInt(this.values[i].count) / sumValueStart) - parseInt(this.gap)
                };
                this.parts.push(tmp);
                start = start + 360 * (parseInt(this.values[i].count) / sumValueStart);
            }

            this.actionsValue = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format((this.actions));
        },

        mounted () {
            // disabled for now
            return;

            const allowedParams = ['filter[]'];

            const params = new URLSearchParams(window.location.search);

            for (const param of params) {
                if (!allowedParams.includes(param[0])) {
                    params.delete(param[0]);
                }
            }

            if (Array.from(params).length === 0) {
                this.filter('sum');
            }

            for (const part of this.parts) {
                for (const param of params) {
                    if (part.filterName === param[1]) {
                        this.filter(part.color);
                    }
                }
            }

            window.history.replaceState({}, '', `${location.pathname}?${params}`);
        },

        methods: {
            filter(color, commit = false) {
                this.filters = [];

                if(color == 'sum') {
                    if (document.getElementById(color).innerHTML == 'X') {
                        // We don't allow the user to de-select all filters (which
                        // would result in no projects being displayed
                        return;
                    }

                    document.getElementById(color).innerHTML = 'X';
                    document.getElementById(color+'m').innerHTML = 'X';

                    for(let i = 0; i < this.values.length; i++) {
                        document.getElementById(this.values[i].color).style.backgroundColor = '#'+this.values[i].color;
                        document.getElementById(this.values[i].color+'m').style.backgroundColor = '#'+this.values[i].color;
                    }
                } else {
                    document.getElementById('sum').innerHTML = '';
                    document.getElementById('summ').innerHTML = '';

                    if(document.getElementById(color).style.backgroundColor != '') {
                        document.getElementById(color).style.backgroundColor = '';
                        document.getElementById(color+"m").style.backgroundColor = '';
                    } else {
                        document.getElementById(color).style.backgroundColor = '#'+color;
                        document.getElementById(color+"m").style.backgroundColor = '#'+color;
                    }
                }

                for(let i = 0; i < this.values.length; i++) {
                    if(document.getElementById(this.values[i].color).style.backgroundColor != '') {
                        this.filters.push(this.values[i].filterName);
                    }
                }

                if (commit) {
                    let params = new URLSearchParams();

                    let numberOfFilters = 0;

                    for (const filter of this.filters) {
                        params.append('filter[]', filter);
                        numberOfFilters++;
                    }

                    // If we filter by everything, we don't need any query
                    // params
                    if (numberOfFilters === this.parts.length) {
                        params = new URLSearchParams();
                    }

                    if (Array.from(params).length === 0 && color !== 'sum') {
                        this.filter('sum', true);
                        return;
                    }

                    window.history.replaceState({}, '', `${location.pathname}?${params}`);

                    window.App.dispatcher.fire(
                        'closedProjectsFilterChange',
                        { filters: this.filters }
                    );
                }
            }
        },

        data() {
            return {
                parts: [],
                filters: [],
                actionsValue: 0
            };
        }
    }
</script>
